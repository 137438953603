<template>
  <div>
    <div class="write">请使用微信扫描二维码签到</div>
    <van-image width="200" height="200" :src="realUrl"/>
  </div>
</template>

<script>
import {getImageStream} from "@/utils/index";
export default {
  components :{

  },
  data() {
    return {
      id: '',
      url: '',
      domain: '',
      realUrl: ''
    };
  },
  created(id) {
    this.url = this.$url
    this.id = this.$route.query.ids
    this.domain = this.$globalData.domain
    this.getCode()
  },
  methods: {
    getCode() {
      // debugger
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/getSignInQrCode'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.id
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.realUrl = getImageStream(data.relativePath)
          console.log(this.realUrl)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.van-image {
  position:absolute;
  left:20%;
  top:25%;
  background-color: #fff;
  box-sizing: border-box;
}
.write {
  position:absolute;
  left:26%;
  top:240px;
  font-size: 30px;
  color: #00a2d3;
}

</style>
